import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import Loading from './pages/UI/loading-spinner/loading-spinner.js';


const NavBar = lazy(() => import('./pages/UI/navBar/navbar.js'));
const HomePage = lazy(() => import('./pages/home-page/home-page.js'));
const AboutPage = lazy(() => import('./pages/about-page/about-page.js'));
const ContactPage = lazy(() => import('./pages/contact-page/contact-page.js'));
const ServicesPage = lazy(() => import('./pages/services-page/services-page.js'));
const Error404 = lazy(() => import('./pages/404/error404.js'));

const Footer = lazy(() => import('./pages/UI/footer/footer.js'));

function App() {
  return (
    <div className='bg-[#f8f8f8]'>
      <Helmet>
        <title>ITFY</title>
      </Helmet>
      {/*<NavBar /> */}
      <Routes>

        <Route path='/' element={<Suspense fallback={<Loading />} ><HomePage /> </Suspense>}></Route>
        <Route path='/about-us' element={<Suspense fallback={<Loading />} ><AboutPage /> </Suspense>}></Route>
        <Route path='/services' element={<Suspense fallback={<Loading />} ><ServicesPage /> </Suspense>}></Route>
        <Route path='/contact-us' element={<Suspense fallback={<Loading />} ><ContactPage /> </Suspense>}></Route>
        <Route path='/*' element={<Suspense fallback={<Loading />} ><Error404 /> </Suspense>}></Route>


      </Routes>

      {/* <Footer />*/}


    </div>);
}

export default App;
